import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "portfolio",
  "title": "Explosiva",
  "thumbnail": "/img/rocket.gif",
  "description": "För dig som vill utforska mer av krypto-universumet och dess möjligheter",
  "percentages": [{
    "percentage": 40,
    "name": "Liquidity pools"
  }, {
    "percentage": 30,
    "name": "Defi"
  }, {
    "percentage": 25,
    "name": "Metaverse"
  }, {
    "percentage": 5,
    "name": "NFTs"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      